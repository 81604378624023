



































import { Component, Vue } from 'vue-property-decorator'
import { BSpinner } from 'bootstrap-vue'

@Component({ components: { BSpinner } })
export default class FoodcardWait extends Vue {

}
